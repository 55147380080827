<template>
    <download-excel class="export-excel-wrapper" :data="json_data" :fields="json_fields" :name="name">
        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
        <button class="derive_but" @click="judge_length">导出已选择媒体</button>
    </download-excel>
</template>

<script>
export default {
    props: {
        json_data: Array,
        json_fields: Object,
        name: String,
        title: String,
        car_placard:{
            type:[String],
            default:'媒体'
        },
    },
    methods: {
        judge_length() {
            console.log(this.car_placard);
            if (this.json_data.length == 0) {
                return this.$message.error(`请选择${this.car_placard}后重试`);
            }
        }
    },
}
</script>

<style lang=scss scoped>
.derive_but {
    padding: 8px 20px;
    box-sizing: border-box;
    border: 0;
    cursor: pointer;
    background: #108CDD;
    color: #fff;
    border-radius: 0.42rem;
    position: absolute;
    top: -45px;
    right: 0;
    z-index: 16;
}
</style>